.homepage__title {
  text-transform: uppercase;
  color: #31678E;
  font-weight: 800;
  margin-bottom: 0.8rem;
}

.homepage__content {
  color: #4B6D69;
  margin: 0.8rem;
}
