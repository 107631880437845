/* Player Styling */
.audio-player {
  width: 8rem;
  height: 5rem;
  border: 3px solid #5cb0cb;
  border-radius: 12.5px;
  overflow: hidden;
  background-color: #37647c;
  --player-button-width: 1.5em;
  position: relative;
  display: none;
}

/* Image */
.icon-container {
  color: #fff;
  display: flex;
  justify-content: right;
  align-items: end;
  margin-top: -1.6rem;
}

.audio-icon {
  position: absolute;
  bottom: 0;
  right: 0.4rem;
  width: auto;
  height: 40px;
}

/* Controls Styling */
.controls-container {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.player-button {
  background-color: white;
  border-radius: 50%;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}

.player-button:focus {
  outline: 1px solid #feffff;
  outline-offset: 0.125rem;
}

.sound-button {
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
  margin-top: 1rem;
}

.sound-button:focus {
  outline: 1px solid white;
  background-color: transparent;
  outline-offset: 0.125rem;
}

/* Timeline Styling */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 5.7rem;
  padding-left: 0.3rem;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #1b3f47;
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -4px;
  background-color: #feffff;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 2px solid #feffff;
  outline-offset: 0.125rem;
}

input[type="range"]::-moz-range-track {
  background-color: #1b3f47;
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type="range"]::-moz-range-thumb {
  background-color: #feffff;
  border: none;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
  outline: 2px solid #feffff;
  outline-offset: 0.125rem;
}
