.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 1rem auto;
}

.page-item {
  margin: 0 0.2rem;
  padding: 0.4rem;
}

.active {
  color: white;
  background-color: #3e6b6c;
}
