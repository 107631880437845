.carousel__mobile {
  margin-top: 12rem;
}

.carousel__wrapper {
  max-width: 300px;
  margin: auto;
  margin-top: 7rem;
}

.carousel__event {
  min-height: 170px;
  min-width: 230px;
  color: white;
  padding: 0.8rem;
  background-color: #880219;
  font-size: 1.3rem;
  font-weight: 800;
  position: relative;
  .carousel__event_date {
    position: absolute;
    bottom: 0;
    padding-bottom: 0.3rem;
    color: #5cb0cb;
    font-size: 1.6rem;
    .carousel__event_time {
      color: white;
      font-size: 2rem;
    }
  }
}

.carousel__event_place {
  color: #fbcc23;
}

.carousel__link {
  display: block;
}

.carousel__title {
  font-family: Futura, Futura-Medium, "Futura Medium", "Centhury Gothic",
    CenthuryGothic, "Apple Gothic";
  position: relative;
  text-transform: uppercase;
  text-align: center;
  font-weight: 1000;
  font-size: 1em;
  background-color: white;
  color: #fbcc23;
  z-index: 1;
  min-height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #dadc3e;
  }
}

.carousel__slide {
  border: 10px solid #1b3f47;
  overflow: hidden;
}

.slick-next:focus .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-prev:focus .slick-prev-icon {
  color: #5cb0cb;
}

.slick-dots li button:focus .slick-dot-icon:before {
  color: #5cb0cb;
}

.slick-autoplay-toggle-button:focus {
  color: #5cb0cb;
}
