/* Header */

.header__wrapper {
  position: relative;
}

.header__banner {
  width: 100vw;
  height: auto;
}

.header__button {
  position: absolute;
  background-color: transparent;
  color: white;
  font-size: 2.5rem;
  border: none;
  left: 1.5%;
  top: -3.5%;
}

/* Title */

.header__title {
  font-size: 2rem;
  text-align: center;
  padding-bottom: 0.8rem;
}

.header__title_white {
  color: #31678E;
}

.header__title_light {
  font-weight: 100;
}

.header__title_city {
  color: #EF0F2C;
}

/* Header Desktop */

.header__wrapper_desktop {
  background-image: url("/images/banner.png");
  background-image: image-set(
    url("/images/banner.webp"),
    url("/images/banner.png")
    );
  background-size: cover;
  background-repeat: no-repeat;
  height: 290px;
  padding: 0;
  margin: 0;
}

/* Player */

.header__player {
  margin-top: -10rem;
  margin-left: 1.1rem;
}
