footer {
  margin-top: auto;
}

.footer {
  text-align: center;
  margin-bottom: 0.8rem;
}

.footer__icon {
  height: 1rem;
  
}

.footer__partner_container {
  display: flex;
  justify-content: center;
}

.footer__partner {
  max-width: 780px;
  width: 100%;
  margin-bottom: 2rem;
}
