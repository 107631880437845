.contact__container {
  border-radius: 5px;
  background-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content;
  position: relative;
}

.contact__header {
  position: absolute;
  text-align: center;
  top: 2rem;
  margin: 0 1rem;
}

.contact__column {
  margin: 1.2rem;
  flex-grow: 1;
  iframe {
    width: 100%;
  }
}

.contact__map {
  margin-top: 2rem;
}
.contact__address {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1.8rem;
}

.form-control {
  width: 100%;
  border-radius: 0;
  border: 1px solid gray;
  margin-bottom: 1em;
  padding: 0.28rem 0;
}

label {
  display: inline-block;
  margin-bottom: 0.5em;
  font-size: 1.2em;
}

.envoyer {
  background-color: #3e6b6c;
  color: white;
  border: 1px solid #3e6b6c;
  font-size: 1.3rem;
  float: right;
  padding: 15px;
}

.envoyer:hover {
  cursor: pointer;
  border: 1px solid #3e6b6c;
  color: #3e6b6c;
  background-color: white;
}
