* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.accessibility {
  width: 100%;
  #a42-ac-button {
    background-color: transparent;
    margin: 16px 0;
    color: #3e6b6c;
    border: none;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
      color: #5cb0cb;
    }
  }
}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  position: static;
  width: auto;
  height: auto;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #4B6D69;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: #3e6b6c;
}

a:hover {
  color: #5cb0cb;
}

main a {
  text-decoration: underline;
}

.carousel__link {
  text-decoration: none;
}
