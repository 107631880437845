/* ACTU */
$brown-actu: #B80000;

.list__container {
  max-width: 365px;
  margin: 10rem auto;
  margin-bottom: 4rem;
  .actu__title {
    text-transform: uppercase;
    color: #DB7F7F;
    font-size: 3rem;
  }
}

.actu__wrapper {
  border: 1px solid #2e3e57;
  max-width: 344px;
  margin: 0.4rem auto;
  padding: 0.4rem;
  overflow: hidden;
  p:first-line {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.actu__content {
  h2 {
    text-transform: uppercase;
  }
}

.actu__cover {
  margin-left: -0.4rem;
  margin-right: 2rem;
}

.actu__infos {
  margin-top: 0.4rem;
  font-size: 1.2rem;
  font-weight: bolder;
}

.actu__wrapper {
  margin-bottom: 1.2rem;
  .actu__description {
    color: white;
    background-color: #FE2132;
    margin-top: -0.4rem;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    padding: 0.2rem;
    padding-right: 1rem;
    font-weight: 800;
    font-size: 1.2rem;
    text-align: right;
  }
  h2 {
    color: $brown-actu;
    font-size: 1.5rem;
    padding: 0.8rem 0;
  }
  a {
    color: #5bab37;
  }
  .actu__lieu {
    color: $brown-actu;
  }
  .actu__indice {
    color: $brown-actu;
  }
  .actu__legende {
    color: $brown-actu;
    margin-left: 1rem;
  }
  .actu__prix {
    font-weight: bolder;
  }
}

/* PODCASTS */
.list__container {
  max-width: 365px;
  .podcast__title {
    text-transform: uppercase;
    color: #7c99ba;
    font-size: 3rem;
  }
}

.podcast__desc {
  font-size: 1.6rem;
  font-weight: bold;
  .podcast__desc_blue {
    color: #5cb0cb;
  }
  .podcast__desc_dark {
    color: #3e6b6c;
  }
}

.podcast__item {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid black;
}

.podcast__item_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.podcast__item_picto {
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.podcast__item_title {
  color: #2c81bc;
  font-size: 1rem;
  font-weight: bold;
}

.podcast__item_desc {
  font-size: 1rem;
  font-weight: bold;
}

.podcast__content {
  padding-top: 1rem;
}

.podcast__content > p::first-line {
  color: #2c81bc;
}

.podcast__content_timing {
  color: #2c81bc;
}

// Trockson

.trockson__title {
  font-size: 3rem;
  color: #9E70A4;
  text-transform: uppercase;
}

.trockson__h2 {
  color: #6A2473;
  text-align: center;
  padding: 1rem;
}

.trockson__h3,
.trockson__blue {
  color: #31678E;
  text-align: center;
  padding: 0.8rem;
}

.trockson__wrapper {
  border: 1px solid #2e3e57;
  margin: 1rem 0rem;
}

.trockson__logo,
.trockson__photo {
  max-width: 340px;
  display: block;
  margin: 0 auto;
}

.trockson__logo {
  padding-top: 1rem;
}

.trockson__photo {
  margin: 1rem auto;
}

.trockson__hr {
  width: 80%;
  margin: 2rem auto;
  background-color: #2e3e57;
  height: 1px;
  border: none;
}

.trockson__hours {
  color: #395088;
  text-align: center;
  font-size: 1.3rem;
}

.trockson__social {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
  .trockson__social_icon {
    height: 40px;
    width: auto;
  }
}

.trockson__text {
  padding: 0 0.8rem !important;
  li {
    margin-left: 1rem;
  }
}

.trockson__imgs {
  padding: 1rem 0 0.5rem 0;
}

.trockson__img {
  margin: 0 auto;
  display: block;
  padding: 1rem;
  max-width: 300px;
}

.trockson__description {
  background-color: #444070 !important;
}

.trockson__actu_wrapper {
  a {
    color: #7b9aaf !important;
  }
  h2,
  .actu__lieu,
  .actu__legende,
  .actu__indice {
    color: #31678E;
  }
}

// Agenda

.agenda__cover {
  display: none;
}

.agenda__left {
  overflow: hidden;
}

.agenda__right_firstpart {
  font-weight: bold;
  color: #243a4e;
}

.agenda__middle {
  h1 {
    text-transform: uppercase;
  }
}
