/* General */

.page__wrapper {
  margin: 9rem 0.4rem;
  margin-bottom: 4rem;

  h1 {
    font-size: 3rem;
  }
  p {
    padding: 0.4rem 0;
  }
}

.homepage__content {
  p {
    padding: 0.4rem 0;
  }
}


.page__wrapper_single {
  img {
    max-width: 100vw;
  }

  h1 {
    margin-top: -3rem;
  }

  h1,
  h2 {
    padding: 1rem 0;
  }
  ul {
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

/* MJC */

.page__mjc_content {
  width: 360px;
  margin: auto;
  h1 {
    color: #31678E;
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  h2 {
    color:  #26535f;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
}

.page__mjc_ext,
.page__mjc_img {
  max-width: 340px;
  padding: 1rem 0;
  display: block;
  margin: auto;
}

.page__mjc_historic {
  padding: 1rem;
}

.page__mjc_yellow {
  color: #d0922c;
}

.page__mjc_wrapper {
  border: 1px solid #2e3e57;
}

/* Jeunesse */

.page__jeunesse_content {
  h1 {
    color: #73B78E;
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  h2 {
    color: #007B32;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
  ul {
    margin-left: 1rem;
  }
}

.page__ij_logo,
.page__ij_img {
  max-width: 95vw;
  padding: 0.8rem 0;
}

.hr__ij {
  margin: 2rem;
}
.page__ij_socials {
  text-align: center;
  img {
    width: 20px;
    height: auto;
  }
}

/* Contact */

.page__contact_content {
  h1 {
    color: #5B897D;
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  h2 {
    color: #165746;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
}

/* TrockSon */

.trockson {
  margin: 0;
}

.container__trockson {
  margin-bottom: 4rem;
}

.trockson__title {
  margin-top: 6rem;
}

.trockson__title_actu {
  margin-top: 0rem;
}

.page__trockson {
  margin-top: 12rem;
}

.page__trockson_content {
  padding: 0 0.5rem;

  h1 {
    color: #9E70A4;
    text-transform: uppercase;
  }
  h2 {
    color: #6A2473;
  }
  h3 {
    color: #31678E;
  }
}

/* Agenda */

.page__agenda_content {
  padding: 0 0.5rem;
  h1 {
    color: #ED7D35;
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  h2 {
    color: #E85D03;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
}

.agenda__header {
  strong {
    color: #fba303;
    font-size: 1.6rem;
  }
}

.agenda__wrapper {
  margin-top: 1.6rem;
  border: 1px solid #2e3e57;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
}

.agenda__middle {
  padding: 1rem;
  position: relative;
  .agenda__more {
    position: absolute;
    bottom: 1rem;
  }
  h1 {
    font-size: 1.5rem;
    color: #3e6b6c;
    font-weight: bolder;
  }
  p:first-line {
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 2rem;
  }
  .agenda__more {
    padding-top: 1rem;
    text-align: right;
  }
}

.agenda__right {
  background-color: #E85D03;
  padding: 1rem;
  .agenda__right_desc {
    color: #243a4e;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .agenda__right_place {
    padding: 1rem 0;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .agenda__right_date {
    color: #243a4e;
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
  .agenda__prix {
    color: #243a4e;
    font-weight: bold;
  }
  .agenda__indice,
  .agenda__legende {
    color: white;
  }
}

.agenda__wrapper:nth-child(even) > .agenda__right {
  background-color: #3cabe2;
}

/* Actu */

.page__actu_content {
  h1 {
    color: #DB7F7F;
    text-transform: uppercase;
    padding: 3.8rem 0;
  }
  h2 {
    color: #B80000;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
}

/* Activités */

.page__activites_content {
  padding: 0 0.5rem;
  h1 {
    color: #EF6C6A;
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  h2 {
    color: #EF0079;
    padding: 0.6rem 0;
  }
  h3 {
    color: #31678E;
    padding: 0.4rem 0;
  }
}

.activites__wrapper {
  margin-top: 1.6rem;
  border: 1px solid #2e3e57;
}

.activites__header {
  strong {
    font-size: 1.6rem;
  }
}

.activites__left {
  background-color: #F8AD13;
  color: white;
  padding: 1rem;
  position: relative;
  min-width: 210px;
  h2 {
    font-size: 2rem;
  }
  img {
    padding: 1rem 0;
  }
  ul {
    list-style-type: none;
    text-align: right;
    font-size: 1.2rem;
    position: absolute;
    margin-top: 1rem;
    bottom: 1rem;
    right: 1rem;
  }
}

.activites__wrapper:nth-child(even) > .activites__left {
  background-color: #488094;
}

.activites__right {
  padding: 1rem;
  h1 {
    font-size: 1rem;
    color: #297db9;
  }
  p {
    padding: 0.4rem 0;
    color: #297db9;
    strong {
      color: #c90036;
    }
  }
  .activites_description {
    color: #44404c;
    &:first-line {
      font-weight: bold;
    }
  }
}
.activites__creneau {
  padding-top: 0.4rem;
  margin-bottom: 2rem;
}

.activites__anim,
.activites__day,
.activites__age {
  color: #297db9;
}

.activites__price,
.activites__hours {
  color: #c90036;
}

.activites__starting {
  color: #f89d00;
}

.activites__starting_date {
  color: #103586;
}

/* Documentation */

.page__documents_content {
  strong {
    font-size: 1.6rem;
  }

  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
    color: #98d25f;
    padding: 0.8rem 0;
  }
  margin-bottom: 4rem;
}

.page__documents_docs {
  display: flex;
  font-size: 1.2rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #8891a1;
  padding-bottom: 1rem;
  padding-top: 1rem;
  &:last-child {
    border-bottom: none;
  }
}
.page__documents_left {
  width: 100vw;
}
.page__documents_middle {
  margin-top: 1rem;
  a {
    border-left: 4px solid #98d15f;
    padding-left: 1rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #8891a1;
  }
}

.page__documents_right {
  margin-top: 1rem;
  img {
    width: 100px;
    height: auto;
  }
}

/* Trockson single */

.page__trockson {
  h1 {
    color: #f4ad85;
  }
  h2 {
    color: #ea5b0c;
  }
  h3 {
    color: #7b9aaf;
  }
}
